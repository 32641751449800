import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { productCardRequest } from '../api_services/api_calls/productCardApi';

const initialState = {
  buyerRequests: [],
  isNewRequestCreated: false,  // Track if a new request was created
  loading: false,
  error: null,
  currentPage: 1,
  searchFilters: {},
  requestFilters: localStorage.getItem("Requestsearchfilter") ?? {},
  isFetching: false,
  isSuccess: false,

};

export const callRequestItems = createAsyncThunk(
  // thunk for call callRequestItems api
  "buyerRequest/callRequestItems",
  async ({ filters, pageNumber }, thunkAPI) => {
    try {
      const response = await productCardRequest(filters, pageNumber)
        .then((res) => {
          if (res?.data) {
            console.log("res", res.data);

            return res.data;
          } else {
            return thunkAPI.rejectWithValue(res.data);
          }
        })
        .catch((err) => {
          thunkAPI.rejectWithValue(err);
        });

      return response;
    } catch (e) {
      thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const requestSlice = createSlice({
  name: 'buyerRequest',
  initialState,
  reducers: {
    setNewRequestCreated: (state, action) => {
      state.isNewRequestCreated = action.payload;
    },
    setRequestdata: (state, action) => {
      state.buyerRequests = action.payload;
      state.currentPage = 1;
    },
    setSearchFilters: (state, action) => {
      state.searchFilters = action.payload;
      localStorage.setItem("Requestsearchfilter", JSON.stringify(action.payload));
    },
    resetRequestFilters: (state) => {
      state.requestFilters = {};
    },
    setRequestFilters: (state, action) => {
      state.requestFilters = action.payload;
      localStorage.setItem("Requestsearchfilter", JSON.stringify(action.payload));
    },
  },

  extraReducers: {
    [callRequestItems.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      return state;
    },
    [callRequestItems.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = false;
      return state;
    },
    [callRequestItems.pending]: (state, { payload }) => {
      state.isFetching = true;
      return state;
    },
  }
});

export const { setNewRequestCreated } = requestSlice.actions;
export const requestActions = requestSlice.actions;

export default requestSlice.reducer;