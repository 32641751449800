import React, { useState, useEffect } from "react";
import AddUserHeader from "./AddUserDetails/AddUserHeader";
import { getContent } from "../api_services/api_calls/contentPageApi";
import Footer from "../components/footer/Footer";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

// "import component via react-lazy load"

const LoaderComponent = () => {
    // loading component for suspense fallback
    return (
        <div className="text-center" style={{ marginTop: "50px", height: "50vh" }}>
            <Skeleton count={3} />
        </div>
    );
};

export default function Page({ match }) {
    const { slug } = match.params; // Access the slug from URL params
    // Fetch data based on the slug, or use a static mapping
    const [pageTitle, setPageTitle] = useState("");
    const [pageContent, setPageContent] = useState("");
    const [loading, setLoading] = useState(false);
    console.log("slug", slug);

    useEffect(() => {
        setLoading(true);
        getContent(slug) // Api function for get page content via api services
            .then((response) => {
                console.log("resp", response);
                setPageTitle(response.data.data.page_title);
                setPageContent(response.data.data.contents);
            })
            .catch((error) => {
                console.log("error");
                setPageTitle("Page Not Found");
                setPageContent("Page Not Found");
            })
            .finally(() => {
                setLoading(false);
                window.scrollTo(0, 0); // Scroll to top of the page
            });
    }, [slug]);

    return (
        <>
            <AddUserHeader />
            <div
                className="container pt-5"
                style={{ marginTop: "100px", marginBottom: "50px" }}
            >
                <div className="row">
                    {loading ? (
                        <LoaderComponent />
                    ) : (
                        <>
                            {" "}
                            <h2 className="col-md-12 text-center text-uppercase ct_fs_32">
                                {pageTitle}
                            </h2>
                            <br />
                            <div className="col-md-12 ct_terms_condition_cnt">
                                <p
                                    dangerouslySetInnerHTML={{
                                        __html: pageContent,
                                    }}
                                ></p>
                            </div>
                        </>
                    )}
                </div>
            </div>
            <Footer />
        </>
    );
}
