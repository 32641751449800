const API_URL = process.env.REACT_APP_API_URL;
const AUTHTOKEN = "token";
const AUTHORIZATION_HEADER = "Bearer";
const URL = process.env.REACT_APP_URL;

export const ApiConfig = { API_URL, AUTHTOKEN, AUTHORIZATION_HEADER };

// "Here are our all the end points of Apis"

// Auth api constants
const SEND_SMS = URL + "/api/send-sms";
const VERIFY_USER__URL = URL + "/api/verify-user";
const LOGIN_URL = URL + "/api/user";
const UPDATE_PROFILE_URL = URL + "/api/update-profile-log";
const CHAT_PROFILE = URL + "/api/chat-profile";
const PROFILE_COMPLETE = URL + "/api/profile-completed";

// "profile-completed"

// edit object seller listing api constants
const UPDATE_LISTING_OBJECT = URL + "/api/listing/update-object/";
const GET_LISTING_OBJECT = URL + "/api/listing/edit-object/";
const OBJECT_LISTING_NEW = URL + "/api/object-filters-new";
const GET_LICENSE_OPTION = URL + "/api/get-license-options";
const GET_EQUIPMENT_OPTION = URL + "/api/get-equipment-options";
const GET_PLEASURE_BOAT_OPTION = URL + "/api/get-pleasureboat-options";
const GET_SEAFOOD_OPTION = URL + "/api/get-seafood-options";
const DELETE_LISTING_SELLER = URL + "/api/listing/delete/";
const EDIT_SELLER_LISTING = URL + "/api/get-listing-by-id-test/";
const DELETE_SELLER_LISTING = URL + "/api/listing/delete-object/";
const EDIT_LISTING_UPDATE = URL + "/api/listing/update/";
const GET_LISTING_TYPE = URL + "/api/get-listing-test?type=Boat";
const GET_LISTING_TYPE_FILTER = URL + "/api/get-listing-test";
const GET_SUB_SPECIES = URL + "/api/get-sub-species/";
const LISTING_DELETE_OBJECT_PHOTO = URL + "/api/listing/delete-object-photo/";
const LISTING_GET_OBJECT_PHOTO = URL + "/api/listing/get-object-photos/";
const REQUEST_GET_OBJECT_PHOTO = URL + "/api/request/get-object-photos/";
const REQUEST_DELETE_OBJECT_PHOTO = URL + "/api/request/delete-object-photo/";
const GET_LISTNG_BY_ID = URL + "/api/get-listing-by-id-test/";
const GET_FAVORITES_LISTING = URL + "/api/get-favorites-listing?type=";
const DELETE_FAVORITE = URL + "/api/delete-favorite/";

// Like listing apis
const LIKE_PRODUCT_LISTING = URL + "/api/like-item";
const LIKE_PRODUCT_REQUEST = URL + "/api/like-request-item";

// Buyer listing object Apis
const GET_REQUEST_TYPE = URL + "/api/get-requests?type=Boat";
const GET_REQUEST_TYPES = URL + "/api/get-requests?type=";
const GET_REQUEST_BY_ID = URL + "/api/get-request-by-id/";
const REQUEST_EDIT_OBJECT = URL + "/api/request/edit-object/";
const BUYER_OBJECT_FILTERS_NEW = URL + "/api/object-filters-new";
const BUYER_GET_LICENSE_OPTION = URL + "/api/get-license-options";
const BUYER_GET_EQUIPMENT_OPTION = URL + "/api/get-equipment-options";
const BUYER_GET_SEAFOOD_OPTION = URL + "/api/get-seafood-options";
const BUYER_REQUEST_UPDATE_OBJECT = URL + "/api/request/update-object/";
const BUYER_REQUEST_DELETE_LISTING = URL + "/api/request/delete/";
const BUYER_REQUEST_DELETE_OBJECT = URL + "/api/request/delete-object/";
const BUYER_REQUEST_UPDATE = URL + "/api/request/update/";
const BUYER_GET_PLEASURE_BOAT_OPTION = URL + "/api/get-pleasureboat-options";

// Refferal Agent listing Apis
const REFERRAL_LISTING_MAKE_SELLER_REQUEST = URL + "/api/make-seller-request";
const REFERRAL_LISTING_OBJECT_FILTERS_NEW = URL + "/api/object-filters-new";
const REFERRAL_LISTING_GET_LICENSE_OPTION = URL + "/api/get-license-options";
const REFERRAL_LISTING_GET_PLEASURE_BOAT_OPTION =
  URL + "/api/get-pleasureboat-options";
const REFERRAL_LISTING_GET_EQUIPMENT_OPTION =
  URL + "/api/get-equipment-options";
const REFERRAL_LISTING__GET_SEAFOOD_OPTION = URL + "/api/get-seafood-options";

// Refferal Agent Request Apis
const REFERRAL_REQUEST_OBJECT_FILTERS_NEW = URL + "/api/object-filters-new";
const REFERRAL_REQUEST_GET_LICENSE_OPTION = URL + "/api/get-license-options";
const REFERRAL_REQUEST_GET_EQUIPMENT_OPTION =
  URL + "/api/get-equipment-options";
const REFERRAL_REQUEST__GET_SEAFOOD_OPTION = URL + "/api/get-seafood-options";
const REFERRAL_REQUEST_MAKE_SELLER_REQUEST = URL + "/api/make-request";

// product card Api
const PRODUCT_CARD = URL + "/api/apply-filter-new?page=";
// const PRODUCT_DETAIL = URL + "/api/product-detail";
const PRODUCT_DETAIL = URL + "/api/product-detail-new";
const PRODUCT_CARD_REQUEST_FILTER = URL + "/api/request-filter?page=";
const PRODUCT_USER_INTEREST = URL + "/api/apply-user-interest";

// membership apis
const GET_USER_PACKAGE = URL + "/api/get-user-package";
const GET_BUYER_PACKAGE = URL + "/api/buyer-package";
const UNSUBSCRIBE_USER_PLAN = URL + "/api/unsubscribe-confirm";
const GET_UPCOMING_INVOICE = URL + "/api/get-upcoming-invoice";

// Stripe menu apis
const STRIPE_MENU_GET_PACKAGE = URL + "/api/get-package/";

// Dashboard Apis
const DASHBOARD_APPLY_FILTER_NEW = URL + "/api/apply-filter-new?page=";
const FEATURED_LISTING = URL + "/api/featured-listing";

// "apply-filter-new?page="

// Sell Dashboard Apis
const REQUEST_FILTER = URL + "/api/request-filter?page=";
const FEATURED_REQUEST = URL + "/api/featured-request?page=";

// Sell map view apis
const SELL_MAP_REQUEST_FILTER = URL + "/api/request-filter";
const SELL_MAP_FILTERS_NEW = URL + "/api/filters-new";

// Home page Apis
const FEATURED_HOME_LISTING = URL + "/api/featured-home-listing";

// sell button listing store
const SELL_BUUTTON_LISTING_STORE = URL + "/api/listing/store";

// sell add item listing store
const SELL_ADD_ITEM_LISTING_STORE = URL + "/api/listing/store-package-object/";

// buy button request store
const BUY_BUTTON_REQUEST_STORE = URL + "/api/request/store";

// dropdown menu filters new
const DROPDOWN_FILTERS_NEW = URL + "/api/filters-new";
const APPLY_FILTERS_NEW = URL + "/api/apply-filter-new";

const REQUEST_FILTERS = URL + "/api/request-filter";

// Auto Search apis
const APPLY_SEARCH = URL + "/api/apply-search";

// Auto Search apis
const APPLY_FILTER_SEARCH = URL + "/api/apply-filter-search?page=";

// client api
const GET_LAST_CHAT_AGENT = URL + "/api/get-last-chat-agent";

// filters Api call
const GET_STATES = URL + "/api/get-filter-states";
const GET_CATEGORY_PRODUCT = URL + "/api/get-category-product/";
const GET_SPECIES_AREA = URL + "/api/get-species-area/";
const GET_CLASS_AREA = URL + "/api/get-class-category/";

// chat user apis
const GET_CHAT_USER_BY_PRODUCT = URL + "/api/get-chat-user-by-product/";
const GET_CHAT_USER_BY_REQUEST = URL + "/api/get-chat-user-by-request/";
const GET_CHAT_AGENT = URL + "get-chat-agent";

// image upload api
const LISTING_VIEW_OBJECT = URL + "/api/listing/view-object/";
const LISTING_ADD_PHOTO = URL + "/api/listing/add-object-photo/";

// referral request store api
const BECOME_REFERRAL_REQUEST_STORE =
  URL + "/api/request/become-referral-agent";

// get page content api
const GET_PAGE = URL + "/api/get-page/";

// jobs api
const GET_JOBS = URL + "/api/get-jobs";
const POST_JOB = URL + "/api/apply-new-job";
const SEARCH_JOB = URL + "/api/search-jobs";
const SEARCH_MY_JOB = URL + "/api/search-my-jobs";
const Filter_JOBS = URL + "/api/search-post-type-job?page=";
const GET_JOB = URL + "/api/jobs/view";
const UPDATE_JOB_STATUS = URL + "/api/change-job-status";
const GET_USER_JOB = URL + "/api/manage-job?page=";
const VIEW_EMAIL_PHONE = URL + "/api/view-email-or-phone";

// update package listing price store api
const SELL_BUUTTON_PACKAGE_LISTING_PRICE_STORE = URL + "/api/listing/update-package-price/";
const GET_COMMERCIAL_VEHILCE_TYPE = URL + "/api/get-commercial-vehicle-type";
export const Url = {
  SEND_SMS,
  VERIFY_USER__URL,
  LOGIN_URL,
  UPDATE_PROFILE_URL,
  UPDATE_LISTING_OBJECT,
  GET_LISTING_OBJECT,
  GET_LISTING_TYPE,
  GET_LISTING_TYPE_FILTER,
  OBJECT_LISTING_NEW,
  GET_LICENSE_OPTION,
  GET_EQUIPMENT_OPTION,
  GET_PLEASURE_BOAT_OPTION,
  GET_SEAFOOD_OPTION,
  GET_REQUEST_TYPE,
  GET_REQUEST_TYPES,
  GET_REQUEST_BY_ID,
  REQUEST_EDIT_OBJECT,
  DELETE_LISTING_SELLER,
  BUYER_OBJECT_FILTERS_NEW,
  BUYER_GET_LICENSE_OPTION,
  BUYER_GET_EQUIPMENT_OPTION,
  BUYER_GET_SEAFOOD_OPTION,
  BUYER_REQUEST_UPDATE_OBJECT,
  SELL_BUUTTON_LISTING_STORE,
  SELL_ADD_ITEM_LISTING_STORE,
  BUY_BUTTON_REQUEST_STORE,
  DROPDOWN_FILTERS_NEW,
  GET_LAST_CHAT_AGENT,
  APPLY_FILTERS_NEW,
  REQUEST_FILTERS,
  BUYER_REQUEST_DELETE_LISTING,
  BUYER_REQUEST_DELETE_OBJECT,
  EDIT_SELLER_LISTING,
  DELETE_SELLER_LISTING,
  EDIT_LISTING_UPDATE,
  BUYER_REQUEST_UPDATE,
  BUYER_GET_PLEASURE_BOAT_OPTION,
  REFERRAL_LISTING_MAKE_SELLER_REQUEST,
  REFERRAL_LISTING_OBJECT_FILTERS_NEW,
  REFERRAL_LISTING_GET_LICENSE_OPTION,
  REFERRAL_LISTING_GET_PLEASURE_BOAT_OPTION,
  REFERRAL_LISTING_GET_EQUIPMENT_OPTION,
  REFERRAL_LISTING__GET_SEAFOOD_OPTION,
  REFERRAL_REQUEST_OBJECT_FILTERS_NEW,
  REFERRAL_REQUEST_GET_LICENSE_OPTION,
  REFERRAL_REQUEST_GET_EQUIPMENT_OPTION,
  REFERRAL_REQUEST__GET_SEAFOOD_OPTION,
  REFERRAL_REQUEST_MAKE_SELLER_REQUEST,
  PRODUCT_CARD,
  PRODUCT_USER_INTEREST,
  DASHBOARD_APPLY_FILTER_NEW,
  PRODUCT_DETAIL,
  GET_USER_PACKAGE,
  GET_BUYER_PACKAGE,
  UNSUBSCRIBE_USER_PLAN,
  STRIPE_MENU_GET_PACKAGE,
  FEATURED_LISTING,
  FEATURED_HOME_LISTING,
  FEATURED_REQUEST,
  REQUEST_FILTER,
  SELL_MAP_REQUEST_FILTER,
  SELL_MAP_FILTERS_NEW,
  APPLY_SEARCH,
  PRODUCT_CARD_REQUEST_FILTER,
  GET_STATES,
  GET_CATEGORY_PRODUCT,
  GET_SPECIES_AREA,
  GET_CLASS_AREA,
  GET_CHAT_AGENT,
  GET_CHAT_USER_BY_PRODUCT,
  GET_CHAT_USER_BY_REQUEST,
  GET_SUB_SPECIES,
  LIKE_PRODUCT_LISTING,
  LIKE_PRODUCT_REQUEST,
  LISTING_VIEW_OBJECT,
  LISTING_ADD_PHOTO,
  CHAT_PROFILE,
  PROFILE_COMPLETE,
  LISTING_DELETE_OBJECT_PHOTO,
  REQUEST_DELETE_OBJECT_PHOTO,
  LISTING_GET_OBJECT_PHOTO,
  REQUEST_GET_OBJECT_PHOTO,
  GET_LISTNG_BY_ID,
  BECOME_REFERRAL_REQUEST_STORE,
  GET_FAVORITES_LISTING,
  DELETE_FAVORITE,
  GET_PAGE,
  GET_JOBS,
  GET_JOB,
  POST_JOB,
  SEARCH_JOB,
  SEARCH_MY_JOB,
  Filter_JOBS,
  UPDATE_JOB_STATUS,
  GET_USER_JOB,
  VIEW_EMAIL_PHONE,
  SELL_BUUTTON_PACKAGE_LISTING_PRICE_STORE,
  GET_COMMERCIAL_VEHILCE_TYPE,
  GET_UPCOMING_INVOICE,
  APPLY_FILTER_SEARCH
};
