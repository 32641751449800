import { Url } from "../api_settings/api_constants";
import { wrappedFetch } from "../api_settings/urlFunctions";

// Api call for product card
export const productCard = (data, id) => {
  return wrappedFetch(Url.PRODUCT_CARD + id, "POST", data);
};

// Api call for product detail
export const productDetail = (data) => {
  return wrappedFetch(Url.PRODUCT_DETAIL, "POST", data);
};

// Api call for post the data for product card request
export const productCardRequest = (data, id) => {
  return wrappedFetch(Url.PRODUCT_CARD_REQUEST_FILTER + id, "POST", data);
};

// Api call for post the data for product card request
export const applyFilterSearch = (data, id) => {
  return wrappedFetch(Url.APPLY_FILTER_SEARCH + id, "POST", data);
};

// Api call for product user interest
export const productUserInterest = (data) => {
  return wrappedFetch(Url.PRODUCT_USER_INTEREST, "POST", data);
};

// like listing item api
export const likeListingItems = (data) => {
  return wrappedFetch(Url.LIKE_PRODUCT_LISTING, "POST", data);
};

// like request item api
export const likeRequestItems = (data) => {
  return wrappedFetch(Url.LIKE_PRODUCT_REQUEST, "POST", data);
};
